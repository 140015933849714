<template>
  <b-row>
    <b-col md="4">
      <template v-if="isLoading">
        <loading></loading>
      </template>
      <b-card v-if="!isLoading" no-body class="card-developer-meetup">
        <div class="card">
          <div class="bg-light-primary rounded-top text-center">
            <b-img
              :src="require('@/assets/images/illustration/demand.svg')"
              alt="Meeting Pic"
              height="170"
            />
          </div>
          <div class="card-body">
            <dl class="row">
              <dt class="col-sm-3">
                {{ $t("form.page.ticket.Ticket Number") }}
              </dt>
              <dd class="col-sm-9">{{ ticketData["ticket_number"] }}</dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-3">{{ $t("form.page.ticket.Category") }}</dt>
              <dd class="col-sm-9">
                {{ ticketData["working_type_alias_name"] }}
              </dd>
            </dl>
            <!--dl class="row">
                <dt class="col-sm-3"> {{$t('form.page.ticket.type')}} </dt>
                <dd class="col-sm-9"> {{ticketData['title_alias_name']}} </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-3"> {{$t('form.page.ticket.Service Impact')}} </dt>
                <dd class="col-sm-9"> {{ticketData['service_impact']}} </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-12"> {{$t('form.page.ticket.Description')}} </dt>
              </dl>
              <dl class="row">
                <dd class="col-sm-12" v-html="ticketData['schedule_task']"></dd>
              </dl-->
          </div>
        </div>
      </b-card>
    </b-col>
    <b-col md="8">
      <template v-if="isLoading">
        <loading></loading>
      </template>
      <b-card v-if="!isLoading" no-body class="card-developer-meetup">
        <div class="bg-light-primary rounded-top text-center">
          <b-img
            :src="require('@/assets/images/illustration/email.svg')"
            alt="Meeting Pic"
            height="170"
          />
        </div>
        <!--b-table :items="ticketData" :fields="fields" class="mb-0">
          <template #cell(schedule_task)="data">
          <div v-html="data.item.schedule_task"></div>
          </template>
          <template #cell(status)="data">
            <b-badge pill variant="light-success" v-if="data.item.finish_time">Finish</b-badge>
            <b-badge pill variant="light-danger" v-else>On Progress</b-badge>
          </template>
          <template #cell(action)="action">
            <b-link @click="viewDetail(action.item.schedule_id)" title="Upgrade">
              <span>Detail</span>
            </b-link>
          </template>
        </b-table-->
        <b-card-body>
          <div class="meetup-header d-flex align-items-center">
            <div class="meetup-day">
              <h6 class="mb-0">{{ day }}</h6>
              <h3 class="mb-0">{{ date }}</h3>
            </div>
            <div class="my-auto">
              <b-card-title class="mb-25">{{
                $t("Ticket History")
              }}</b-card-title>
              <b-card-text class="mb-0">{{ month }}</b-card-text>
            </div>
          </div>
          <app-timeline v-if="!isLoading">
            <app-timeline-item v-if="ticketCreatedBy == null" variant="primary">
              <b-media style="margin-bottom:10px;">
                <template #aside>
                  <feather-icon size="24" :icon="'SettingsIcon'" />
                </template>
                <div v-html="defaultText"></div>
              </b-media>
            </app-timeline-item>
            <app-timeline-item
              v-for="t in historyList"
              :key="t.id"
              variant="primary"
            >
              <b-media style="margin-bottom:10px;">
                <template #aside>
                  <feather-icon
                    size="24"
                    :icon="'SettingsIcon'"
                    v-if="t.status == 1"
                  />
                  <feather-icon
                    size="24"
                    :icon="'TwitchIcon'"
                    v-if="t.status == 2"
                  />
                </template>
                <div v-html="t.activity"></div>
              </b-media>
            </app-timeline-item>
          </app-timeline>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Loading from "@core/components/loading/Loading.vue";
import { required, email } from "@validations";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormSelect,
  BFormTextarea,
  BCardBody,
  BCardHeader,
  BTable,
  BImg,
  BCard,
  BLink,
  BBadge,
  BMedia,
  BMediaAside,
  BCardTitle,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BCardHeader,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    ToastificationContent,
    BFormTextarea,
    //HistoryTiket,
    BCardBody,
    BTable,
    BImg,
    BCard,
    Loading,
    BLink,
    BBadge,
    BMedia,
    BMediaAside,
    AppTimeline,
    AppTimelineItem,
    BCardTitle,
  },
  data() {
    return {
      historyList: [],
      ticketCreatedBy: null,
      defaultText:
        'Schedule created <br /><small class="pull-right bottom-right"><b>by ' +
        localStorage.getItem("name") +
        "</b></small>",
      day: null,
      date: null,
      month: null,
      isLoading: false,
      ticketData: [],
      showemptyHistory: false,
    };
  },
  computed: {
    rows() {
      return false;
    },
  },
  methods: {
    init(route) {
      var dateobj = new Date();
      this.gethistory(route);
    },
    gethistory: function(schedule_id) {
      this.isLoading = true;
      this.historyList = [];
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios
        .get("ticket/ticket_history/" + schedule_id)
        .then((response) => {
          this.historyList = response.data.data;
          this.day = response.data.ticket_day;
          this.date = response.data.ticket_date;
          this.month = response.data.ticket_month;
          this.ticketData = response.data.ticket_detail_history;
          this.ticketCreatedBy = response.data.ticket_created_by;
          if (!this.historyList) {
            this.showemptyHistory = true;
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ticket",
              icon: "EditIcon",
              variant: "success",
              text: response.data.message,
            },
          });
        })
        .catch((error) => {
          if (error.response.data.message == "Expired token") {
            localStorage.clear();
            this.$router.push({ name: "login" });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Ticket",
                icon: "EditIcon",
                variant: "success",
                text: "Session Expired, Please Login Again",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Ticket",
                icon: "EditIcon",
                variant: "success",
                text: error.response.data.message,
              },
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    let route = this.$route.path.split("/");
    this.init(route[2]);
  },
};
</script>
